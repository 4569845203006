<template>
  <v-footer class="py-4" dark height="auto">
    <v-container class="mx-auto">
      <v-row>
        <v-col cols="9">
          <base-btn v-for="(link, i) in links" :key="i" :href="link.href" class="ml-0 mr-3" color="primary" square
            target="_blank">
            <v-icon v-text="link.icon" />
          </base-btn>
          <base-btn href="https://www.tiktok.com/@center.qamar" class="ml-0 mr-3" color="primary" square target="_blank"><v-img max-height="50" max-width="50"
              :src="require('@/assets/tik3.png')"></v-img></base-btn>
        </v-col>

        <v-spacer />

        <base-btn class="mr-0" square title="Go to top" @click="$vuetify.goTo(0)" color="primary">
          <v-icon>mdi-chevron-up</v-icon>
        </base-btn>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'CoreFooter',

  data: () => ({
    links: [
      {
        href: 'https://www.facebook.com/alqamar.cen',
        icon: 'fa-facebook-square',
        color: '#3b5998 '
      },
      {
        href: 'https://www.instagram.com/alqamar_cen',
        icon: 'fa-instagram',
        color: '#fb3958'
      },

      {
        href: 'https://t.me/alqamar_cen',
        icon: 'fa-telegram',
        color: '#2AABEE'
      },
      {
        href: 'https://www.youtube.com/channel/UCpN36-3mIoIGzJjxBZJ7HKw',
        icon: 'fa-youtube',
        color: '#FF0000'
      },
    ]
  }),
}
</script>
